<template>
  <div
    v-if="!props.params.node?.group && props.params.node?.rowPinned !== 'bottom'"
    class="elv-financials-cell-address-main"
  >
    <img
      v-if="props.params.value?.cellName === 'Address/Bank Account' && props.params.data?.platform?.logo"
      :onerror="useDefaultImage"
      class="elv-financials-cell-address-main__logo"
      :src="props.params.data?.platform?.logo"
      :alt="props.params.data?.platform?.name"
    />
    <SvgIcon
      v-else-if="props.params.data?.platform?.logo === null && props.params.data?.platform?.type === 'MARKET'"
      class="elv-financials-cell-address-main__logo"
      name="sources-csv"
      width="20"
      height="20"
    />
    <img
      v-else-if="props.params.value?.cellName === 'Address/Bank Account' && !props.params.data?.platform?.logo"
      :onerror="useDefaultImage"
      class="elv-financials-cell-address-main__logo"
      src="@/assets/img/reports/default-entity-logo.png"
      alt="logo"
    />
    <template v-if="props.params.value?.tableType === 'accounts' && props.params.data?.platform?.type === 'EXCHANGE'">
      <!-- <div class="elv-financials-cell-address-main__address" :data-clipboard-text="params.data?.address">
        {{ params.data?.address ? params.data?.address : params.data?.value }}
      </div>
      <img
        src="@/assets/img/copy.png"
        alt="copy"
        class="elv-financials-cell-address-main__copy"
        @click="onClickedCopy(params.data?.address ? params.data?.address : params.data?.value)"
      /> -->
      <div class="elv-financials-cell-address-main__exchange">-</div>
    </template>
    <!-- <div v-else class="elv-financials-cell-address-main__exchange">-</div> -->
    <div
      v-else
      class="elv-financials-cell-address-main__address"
      :data-clipboard-text="params.data?.address ?? params.data?.cardNumber ?? params.data?.value"
    >
      {{ params.data?.address ? params.data?.address : params.data?.value ?? params.data?.cardNumber }}
    </div>
    <img
      v-if="
        props.params.data?.platform?.type === 'CHAIN' ||
        props.params.data?.platform?.type === 'BANK' ||
        props.params.data?.platform?.type === 'EXCHANGE'
      "
      src="@/assets/img/copy.png"
      alt="copy"
      class="elv-financials-cell-address-main__copy"
      @click="
        onClickedCopy(params.data?.address ? params.data?.address : params.data?.value ?? params.data?.cardNumber)
      "
    />
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import defaultImg from '@/assets/img/reports/default-entity-logo.png'

const { t } = useI18n()
const { toClipboard } = useClipboard()

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('common.copied'))
  } catch (e) {
    console.error(e)
  }
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}
</script>
<style lang="scss">
.elv-financials-cell-address-main {
  display: flex;
  align-items: center;
  cursor: pointer;

  .elv-financials-cell-address-main__logo {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .elv-financials-cell-address-main__address {
    height: 16px;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
  }

  .elv-financials-cell-address-main__copy {
    padding-left: 4px;
    width: 12px;
    height: 12px;
  }

  .elv-financials-cell-address-main__exchange {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }
}
</style>
