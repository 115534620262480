import { isEmpty } from 'lodash-es'
import { processTableFieldList } from '@/config/reports'
import indexCell from '@/components/Reports/Cell/IndexCell.vue'
import subHeaderCell from '@/components/Reports/Cell/SubHeaderCell.vue'

// 加载字段配置
const configCellModules: Record<string, any> = import.meta.glob('./*.ts', {
  eager: true
})
const configCellModulesMap = new Map()
Object.keys(configCellModules).forEach((modules) => {
  const key = configCellModules[modules]
  if (!isEmpty(key.default)) {
    configCellModulesMap.set(key.default?.type, key.default?.config)
  }
})
// 表格展示内容
// eslint-disable-next-line no-unused-vars
const tableValueGetter = (field: any, type: string) => {
  return (params: any) => {
    return { cellName: field.name, tableType: type, ...params.data }
  }
}
const tableWidthGetter = (item: any) => {
  let width: any = 200
  if (item.name === 'Description' || item.name === 'Address' || item.name === 'From/To') {
    width = 400
  } else if (item.name === 'Address/Bank Account' || item.name === 'Memo') {
    width = 500
  }
  return width
}

export const useReportsTableConfigStore = defineStore({
  id: 'reportsTableConfigStore',
  state: () => {
    const reportsTableConfigState: {
      processTableConfig: any[]
    } = {
      processTableConfig: []
    }
    return reportsTableConfigState
  },
  actions: {
    /**
     * 获取过程表配置
     * @param {string} type 表格类型(generalLedger、transactions、holdings、accounts、contacts)
     * @returns
     */
    getProcessTableConfig(type: string, pinnedList: string[] = [], pinned: string = 'left') {
      const { fieldGroupList } = processTableFieldList[type]
      const tableConfig: any[] = [
        // 定义索引和分组字段配置
        {
          headerName: '',
          colId: '#',
          field: '#',
          suppressMenu: false,
          headerComponent: subHeaderCell,
          cellRenderer: indexCell,
          suppressAutoSize: false,
          suppressSizeToFit: true,
          valueGetter: tableValueGetter({}, type),
          width: 36,
          cellStyle: {
            padding: 0
          },
          colSpan: (params: any) => {
            const rowData = params.node
            if (rowData.rowPinned === 'bottom' && rowData.rowIndex === 0) {
              return 2
            }
            if (rowData.data?.type === 'total') {
              return 8
            }
            return 1
          },
          resizable: false // 是否开启调整列大小，就是拖动改变列大小
        }
      ]

      if (isEmpty(fieldGroupList)) {
        return tableConfig
      }

      // 处理字段分组和字段展示
      fieldGroupList?.forEach((fieldItem: any, i: number) => {
        // 根据字段类型找到具体的字段配置
        const config = configCellModulesMap.get(fieldItem.type) ?? {}
        // 拼接分组数据
        const formatField: any = {
          headerName: fieldItem.name, // 标题名
          headerComponent: subHeaderCell,
          headerTooltip: '',
          field: fieldItem.fieldId, // 数据
          colId: fieldItem.fieldId,
          rowGroup: false,
          pinned: pinnedList.includes(fieldItem.name) ? pinned : null,
          width: tableWidthGetter(fieldItem),
          suppressSizeToFit: fieldItem.name !== 'From/To',
          suppressAutoSize: fieldItem.name === 'From/To',
          columnTypes: fieldItem.type,
          valueGetter: tableValueGetter(fieldItem, type),
          columnIndex: i,
          hide: false,
          ...config
        }
        tableConfig.push(formatField)
      })

      // 分组字段的配置
      if (type !== 'transactions' && type !== 'contactsUnnamed') {
        const groupFieldConfig = {
          headerName: 'group',
          field: 'firstLevelGroup',
          rowGroup: true,
          showRowGroup: true,
          suppressAutoSize: false,
          suppressSizeToFit: true,
          hide: true
        }
        tableConfig.push(groupFieldConfig)
      }
      if (pinnedList.includes('Operating')) {
        // 根据字段类型找到具体的字段配置
        const config = configCellModulesMap.get('Operating') ?? {}
        // 拼接分组数据
        const formatField: any = {
          headerName: 'Operating', // 标题名
          headerComponent: subHeaderCell,
          headerTooltip: '',
          field: 'Operating',
          colId: 'Operating',
          rowGroup: false,
          pinned: 'right',
          width: 90,
          suppressAutoSize: false,
          suppressSizeToFit: true,
          columnTypes: 'Operating',
          valueGetter: tableValueGetter({}, type),
          hide: false,
          ...config
        }
        tableConfig.push(formatField)
      }
      this.processTableConfig = tableConfig
      return tableConfig
    }
  }
})
