<template>
  <div class="elv-transactions-review-container">
    <p class="elv-transactions-review-title">
      {{
        props.params?.data?.categoryNo !== '00000'
          ? t('report.transactionsRule', { total: reportStore.batchEditSelected.length })
          : t('report.similarTransactions', { total: reportStore.batchEditSelected.length })
      }}
    </p>
    <ul class="elv-transactions-review-content">
      <li
        v-if="currentEventData?.categoryName && props.params?.data?.categoryNo !== '00000'"
        class="elv-transactions-review-event-title"
      >
        {{ currentEventData?.categoryName }}
      </li>
      <li class="elv-transactions-review-from">
        <p class="elv-transactions-review-from__title">{{ t('report.from') }}</p>
        <p class="elv-transactions-review-from__content">
          <span v-if="currentEventData?.categoryName && props.params?.data?.categoryNo !== '00000'">{{
            fromData.title
          }}</span>
          <span
            >{{ fromData.content }}
            <SvgIcon
              v-if="fromData.type === 'address'"
              name="sources-copy"
              width="12"
              height="12"
              @click="onClickedCopy(props.params?.data?.fromAddress)"
          /></span>
        </p>
      </li>
      <li class="elv-transactions-review-to">
        <p class="elv-transactions-review-to__title">{{ t('report.to') }}</p>
        <p class="elv-transactions-review-to__content">
          <span v-if="currentEventData?.categoryName && props.params?.data?.categoryNo !== '00000'">{{
            toData.title
          }}</span>
          <span
            >{{ toData.content
            }}<SvgIcon
              v-if="toData.type === 'address'"
              name="sources-copy"
              width="12"
              height="12"
              @click="onClickedCopy(props.params?.data?.toAddress)"
          /></span>
        </p>
      </li>
      <li
        v-if="!currentEventData?.categoryName || props.params?.data?.categoryNo === '00000'"
        class="elv-transactions-review-currency"
      >
        <p class="elv-transactions-review-currency__title">{{ t('report.Currency') }}</p>
        <div class="elv-transactions-review-to__content">
          <img
            v-if="currencyData.logo"
            class="elv-transactions-review-currency__logo"
            :onerror="useDefaultImage"
            :src="currencyData.logo"
            :alt="currencyData.name"
          />
          <img
            v-else
            class="elv-transactions-review-currency__logo"
            src="@/assets/img/default-logo.png"
            alt="elven"
            :onerror="useDefaultImage"
          />
          <span>{{ currencyData.name }}</span>
        </div>
      </li>
      <li class="elv-transactions-review-line">
        <img src="@/assets/img/reports/review-arrow.png" alt="arrow" :onerror="useDefaultImage" />
      </li>
      <li class="elv-transactions-review-event">
        <p class="elv-transactions-review-event__title">{{ t('report.setEvent') }}</p>
        <p class="elv-transactions-review-event__text">
          <span v-if="currentEventData?.categoryName && props.params?.data?.categoryNo !== '00000'">{{
            currentEventData?.categoryName
          }}</span>

          <el-popover
            v-else
            ref="eventSelectRef"
            placement="bottom-start"
            trigger="click"
            popper-class="elv-transactions-review-event-popper"
            :show-arrow="true"
            :offset="0.2"
            @before-enter="onCheckSelectArrow(true)"
            @before-leave="onCheckSelectArrow(false)"
          >
            <template #reference>
              <div class="elv-transactions-review-event-select">
                <div
                  v-if="
                    currentEventData?.primaryGrouping &&
                    currentEventData?.subGrouping &&
                    currentEventData?.categoryName &&
                    currentEventData?.categoryName !== 'No set'
                  "
                >
                  <p>{{ currentEventData?.primaryGrouping }} / {{ currentEventData?.subGrouping }}</p>
                  <span>{{ currentEventData?.categoryName }}</span>
                </div>
                <p v-else class="elv-transactions-review-event-select__noSet">{{ t('report.noSet') }}</p>
                <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: arrowRotate }" />
              </div>
            </template>

            <p class="elv-transactions-review-event-option-title">
              Showing all labels with&nbsp;<span>token flow: {{ tokenFlowDirection }}</span>
            </p>
            <TransactionOverlayDropdown
              ref="eventDropdownRef"
              :drop-down-data="reportStore.eventDataList(eventDataScope)"
              :current-data="props.params?.data"
              :show-checkbox-input="false"
              @onCloseEventSelect="onCloseEventSelect"
            />
          </el-popover>
        </p>
      </li>
    </ul>
    <el-button
      type="primary"
      class="elv-transactions-review-confirm"
      :loading="confirmLoading"
      :disabled="currentEventData?.categoryName === 'No set'"
      @click="onBatchReview"
      >{{ t('button.confirm') }}</el-button
    >

    <div class="elv-transactions-review-back" @click="onBackList">{{ t('button.backList') }}</div>
  </div>
</template>

<script setup lang="ts">
import { map, find, difference } from 'lodash-es'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import useClipboard from 'vue-clipboard3'
import { reportEventList } from '@/config/reports'
import defaultImg from '@/assets/img/default-logo.png'
import { useReportStore } from '@/stores/modules/reports/index'
import TransactionOverlayDropdown from '@/pages/Financials/Project/Transactions/components/OverlayDropdown.vue'

const props: any = defineProps<{ params: any }>()

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const { toClipboard } = useClipboard()

const eventSelectRef = ref()
const eventDropdownRef = ref()
const confirmLoading = ref(false)
const arrowRotate = ref('rotate(90deg)')

// eslint-disable-next-line no-unused-vars
const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const fromData = computed(() => {
  const data = {
    title: '',
    content: '',
    type: ''
  }

  if (props.params?.data?.fromEntityAccount?.address) {
    data.title = t('common.account')
    data.type = props.params?.data?.fromEntityAccount?.name ? 'account' : 'address'
    data.content =
      props.params?.data?.fromEntityAccount?.name ?? utils.formatInputAddress(props.params?.data?.fromAddress)
  } else if (props.params?.data?.fromAddressContact?.role) {
    data.title = t('common.contact')
    data.type = props.params?.data?.fromAddressContact?.name ? 'contact' : 'address'
    data.content =
      props.params?.data?.fromAddressContact?.name ?? utils.formatInputAddress(props.params?.data?.fromAddress)
  } else {
    data.type = 'address'
    data.content = utils.formatInputAddress(props.params?.data?.fromAddress)
    data.title = props.params?.data?.direction === 'OUT' ? t('common.account') : t('common.contact')
  }
  return data
})

const toData = computed(() => {
  const data = {
    title: '',
    content: '',
    type: ''
  }
  if (props.params?.data?.toEntityAccount?.address) {
    data.title = t('common.account')
    data.type = props.params?.data?.toEntityAccount?.name ? 'account' : 'address'
    data.content = props.params?.data?.toEntityAccount?.name ?? utils.formatInputAddress(props.params?.data?.toAddress)
  } else if (props.params?.data?.toAddressContact?.role) {
    data.title = t('common.contact')
    data.type = props.params?.data?.toAddressContact?.name ? 'contact' : 'address'
    data.content = props.params?.data?.toAddressContact?.name ?? utils.formatInputAddress(props.params?.data?.toAddress)
  } else {
    data.type = 'address'
    data.content = utils.formatInputAddress(props.params?.data?.toAddress)
    data.title = props.params?.data?.direction === 'IN' ? t('common.account') : t('common.contact')
  }
  return data
})

const currentEventData = computed(() => {
  let data: any = {}
  if (eventDropdownRef.value?.currentCheckedEvent) {
    data = eventDropdownRef.value?.currentCheckedEvent
  } else {
    data = props.params?.data?.categoryNo ? find(reportEventList, { categoryNo: props.params?.data?.categoryNo }) : {}
  }
  return data
})

const tokenFlowDirection = computed(() => {
  let directionValue = ''
  switch (currentEventData.value?.tokenDirection) {
    case 'in':
      directionValue = 'in'
      break
    case 'out':
      directionValue = 'out'
      break
    default:
      directionValue = '/'
      break
  }
  return directionValue
})

const eventDataScope = computed(() => {
  let scope = 'all'

  if (props.params?.data?.direction !== 'INTERNAL') {
    scope = props.params?.data?.direction === 'OUT' ? 'out' : 'in'
  } else {
    scope = 'internal'
  }
  return scope
})

const currencyData = computed(() => {
  const data = {
    logo: '',
    name: ''
  }
  data.name = props.params?.data?.currency?.symbol ?? props.params?.data?.currency
  const currency: any = find(reportStore.currencyList, { symbol: props.params?.data?.currency })
  data.logo = props.params?.data?.currency?.logo ? props.params?.data?.currency?.logo : currency?.logo
  return data
})

const onCheckSelectArrow = (status: boolean) => {
  arrowRotate.value = status ? 'rotate(-90deg)' : 'rotate(90deg)'
}

const onCloseEventSelect = () => {
  unref(eventSelectRef)?.hide?.()
}

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('common.copied'))
  } catch (e) {
    console.error(e)
  }
}

const onBackList = () => {
  props.params?.columnApi?.setColumnsVisible(['transactions-event', 'Operating'], true)
  props.params?.api?.closeToolPanel()
  reportStore.batchEditSelected = []
  reportStore.unSelectedList = []
  reportStore.checkTransactionsAll = false
  reportStore.batchReviewParams = {}
  emitter.emit('resetList')
}

const onBatchReview = async () => {
  try {
    if (
      (eventDropdownRef.value?.currentCheckedEvent?.categoryNo || props.params?.data?.categoryNo) &&
      eventDropdownRef.value?.currentCheckedEvent?.categoryNo !== '00000'
    ) {
      confirmLoading.value = true
      const params: any = {
        activityIds: [],
        categoryNo: eventDropdownRef.value?.currentCheckedEvent?.categoryNo
      }
      const activityIdList = map(reportStore.batchEditSelected, 'activityId')
      params.activityIds = difference(activityIdList, reportStore.unSelectedList)
      if (props.params?.data?.categoryNo === '00000') {
        await ReportsApi.quickEditEvent(entityId.value, params)
      } else {
        await ReportsApi.quickReview(entityId.value, params)
      }
      if (reportStore.entityDetail.guideStep < 2) {
        reportStore.fetchEntityDetail(entityId.value)
      }
      reportStore.fetchIssueDataList(entityId.value)
      reportStore.unSelectedList = []
      reportStore.checkTransactionsAll = false
      ElMessage.success(t('message.transactionCategorized'))
      reportStore.batchReviewParams = {}
      onCloseEventSelect()
      onBackList()
      const { data } = await ReportsApi.getActivityEvent(entityId.value, { status: 'REVIEWED' })
      const transactionFilter = {
        ...reportStore.transactionFilter,
        categoryNos: data
      }
      reportStore.processTableListDataInit(entityId.value, 'reviewed', {
        limit: 10,
        page: 1,
        ...transactionFilter
      })
    } else {
      // 未选择event
      ElMessage.warning('请选择event')
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    confirmLoading.value = false
  }
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}
</script>

<style lang="scss">
.elv-transactions-review-container {
  width: 100%;
  box-sizing: border-box;
  padding: 11px 12px;

  .elv-transactions-review-title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #1e2024;
    margin-bottom: 8px;
  }

  .elv-transactions-review-content {
    width: 234px;
    padding-bottom: 8px;
    border: 1px solid #edf0f3;
    border-radius: 3px;

    .elv-transactions-review-event-title {
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      padding: 12px 8px;
      width: 100%;
      height: 43px;
      background: #f9fafb;
      border-bottom: 1px solid #edf0f3;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      text-transform: capitalize;
      color: #1e2024;
    }

    .elv-transactions-review-from {
      border-bottom: 1px solid #edf0f3;
      box-sizing: border-box;
      width: 100%;
      padding: 8px;
      min-height: 50px;

      .elv-transactions-review-from__title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        color: #aaafb6;
        margin-bottom: 4px;
      }

      .elv-transactions-review-from__content {
        width: 100%;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;

        span {
          display: block;
          width: 50%;
        }
      }
    }

    .elv-transactions-review-to {
      box-sizing: border-box;
      width: 100%;
      padding: 8px;
      min-height: 50px;

      .elv-transactions-review-to__title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        color: #aaafb6;
        margin-bottom: 4px;
      }

      .elv-transactions-review-to__content {
        width: 100%;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;

        span {
          display: block;
          width: 50%;

          svg {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }

    .elv-transactions-review-currency {
      border-top: 1px solid #edf0f3;
      box-sizing: border-box;
      width: 100%;
      padding: 8px;
      min-height: 50px;

      .elv-transactions-review-currency__title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        color: #aaafb6;
        margin-bottom: 4px;
      }

      .elv-transactions-review-to__content {
        display: flex;
        align-items: center;

        img {
          display: block;
          width: 14px;
          height: 14px;
        }

        span {
          margin-left: 5px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
        }
      }
    }

    .elv-transactions-review-line {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        display: block;
        width: 22px;
        height: 22px;
      }

      &::after {
        width: 102px;
        height: 1px;
        content: '';
        background-color: #edf0f3;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        width: 102px;
        height: 1px;
        content: '';
        background-color: #edf0f3;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .elv-transactions-review-event {
      box-sizing: border-box;
      width: 100%;
      padding: 8px;
      min-height: 50px;

      .elv-transactions-review-event__title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        color: #aaafb6;
        margin-bottom: 4px;
      }

      .elv-transactions-review-event__text {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;
      }
    }
  }

  .elv-transactions-review-confirm {
    width: 100%;
    height: 36px;
    margin-top: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    background: #1753eb;
    box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
    border-radius: 3px;
    border: 0px;

    &.is-disabled {
      background: #edf0f3;
      box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #a3a3a3;
      color: #838d95;
    }

    &:not(.is-disabled):hover {
      background-color: #2f63eb;
    }

    &:not(.is-disabled):active {
      background-color: #1343bf;
    }
  }

  .elv-transactions-review-back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    margin-top: 10px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #636b75;
    background: #fff;
    border-radius: 3px;
    cursor: pointer;
  }
}

.el-popper.elv-transactions-review-event-popper {
  padding: 0px;

  .elv-transactions-review-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}

.elv-transactions-review-event-select {
  box-sizing: border-box;
  height: 53px;
  width: 100%;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dde1e6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin: 8px 0;

  .elv-transactions-review-event-select__noSet {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #d0d4d9;
  }

  > div {
    display: flex;
    flex-direction: column;

    p {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      color: #aaafb6;
      margin-bottom: 8px;
    }

    span {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #0e0f11;
    }
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #5e85eb;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

    svg {
      fill: #5e85eb;
    }
  }

  svg {
    fill: #838d95;
    transition: transform 0.3s;
  }
}
</style>
