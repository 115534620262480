<template>
  <div
    v-if="props.params.column.colId !== '#'"
    class="elv-financials-header-special"
    :style="{ cursor: 'default', height: '34px' }"
  >
    {{ props.params.displayName !== 'Operating' ? t(`report.${props.params.displayName}`) : '' }}
  </div>
  <div v-else class="elv-financials-header-index">
    <el-checkbox
      v-if="route.name === 'reports-transactions-for-review' || route.name === 'reports-transactions-categorized'"
      v-model="reportStore.checkTransactionsAll"
      class="elv-transactions-index-select"
      @change="onCheckAllTransactions"
    />
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { useReportStore } from '@/stores/modules/reports/index'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const props: any = defineProps<{ params: any }>()

const cellJustifyContent = computed(() => {
  const endList = ['Quantity', 'Cost Basis', 'Fair Value', 'Amount (In)', 'Amount (Out)']
  if (endList.includes(props.params.displayName)) {
    return 'flex-end'
  }
  return 'flex-start'
})

const onCheckAllTransactions = () => {
  emitter.emit('onCheckAllTransactions')
}
</script>
<style lang="scss">
.elv-financials-header-content,
.elv-financials-header-special {
  padding-left: 12px;
  padding-right: 11px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: v-bind('cellJustifyContent');
  height: 30px;
  width: 100%;
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: $elv-color-666D74;
  background: $elv-color-EEF4FB;

  &:hover {
    color: $elv-color-666D74;
  }

  .elv-financials-header-sort-icon {
    margin-top: 4px;
  }

  .elv-financials-header-menu-icon {
    visibility: hidden;
    width: 36px;
    height: 34px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .elv-financials-header-menu-icon-triangle {
      position: absolute;
      top: 12.5px;
      left: 16.5px;
    }
  }
}

.elv-financials-header-index {
  background: $elv-color-EEF4FB;
  height: 100%;
  width: 100%;
  position: relative;
}

.elv-transactions-index-select {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateX(-50%);
  transform: translateY(-50%);

  .el-checkbox__label {
    display: none;
  }

  &.el-checkbox {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    margin-right: 0px;

    &.is-checked {
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #1e2024;
      }
    }

    .el-checkbox__inner {
      background-color: #eef4fb;
      border-color: #d0d4d9;
    }

    &:hover {
      .el-checkbox__inner {
        border-color: #1753eb;
      }
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #1753eb;

      &:after {
        left: 3.5px;
      }
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #fff;

      &::before {
        height: 2.5px;
        top: 4.5px;
        background-color: #1753eb;
      }
    }
  }
}
</style>
