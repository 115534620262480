<template>
  <div>
    <div
      v-if="
        props.params.node?.group && props.params?.colDef.columnTypes === 'DateTime' && props.params?.data?.description
      "
      class="elv-screener-header-tooltips"
    >
      {{ utils.formatLanguageContent(props.params?.data?.description) }}
    </div>
    <div
      v-else-if="
        props.params?.value?.tableType === 'transactions' &&
        props.params?.colDef.columnTypes === 'Event' &&
        props.params?.data?.description
      "
      class="elv-screener-total-tooltips"
    >
      {{ utils.formatLanguageContent(props.params?.data?.description) }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'

const props: any = defineProps<{
  params: any
}>()
</script>
<style lang="scss">
.elv-screener-header-tooltips {
  padding: 16px;
  width: 269px;
  background: #ffffff;
  border: 1px solid $elv-color-E4E7EB;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.elv-screener-header-group-tooltips {
  box-sizing: border-box;
  padding: 16px;
  background: #ffffff;
  border: 1px solid $elv-color-E4E7EB;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: $elv-color-0E1420;
  font-size: 11px;

  span {
    font-weight: 700;
  }
}

.elv-screener-total-tooltips {
  box-sizing: border-box;
  padding: 16px;
  background: #ffffff;
  border: 1px solid $elv-color-E4E7EB;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: $elv-color-0E1420;
  font-size: 11px;

  span {
    font-weight: 700;
  }
}

.elv-total-project {
  span {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    color: $elv-color-999BA0;
    margin-left: 12px;
  }
}
</style>
