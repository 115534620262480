<template>
  <div class="elv-financials-cell-addressFromTo-item-main" @click="onClickedCopy(props.address)">
    <img v-if="props.isShowLogo" :src="infoImg" alt="" class="elv-financials-cell-addressFromTo-item-main__logo" />
    <div class="elv-financials-cell-addressFromTo-main__address" :data-clipboard-text="props.address">
      {{ utils.formatInputAddress(props.address) }}
    </div>
    <img src="@/assets/img/copy.png" alt="" class="elv-financials-cell-addressFromTo-main__copy" />
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import { useReportStore } from '@/stores/modules/reports/index'

const { t } = useI18n()
const { toClipboard } = useClipboard()

const reportStore = useReportStore()

const props = defineProps({
  address: {
    type: String,
    required: true,
    default: ''
  },
  isShowLogo: {
    type: Boolean,
    default: false
  }
})

const infoImg = computed(() => {
  return reportStore.entityDetail.logo
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage(t('common.copied'))
  } catch (e) {
    console.error(e)
  }
}
</script>
<style lang="scss" scoped>
.elv-financials-cell-addressFromTo-item-main {
  cursor: pointer;
  background: $elv-color-EDF0F3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 12px;

  .elv-financials-cell-addressFromTo-item-main__logo {
    height: 14px;
    width: 14px;
  }

  .elv-financials-cell-addressFromTo-main__address {
    height: 16px;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
    padding-left: 4px;
  }

  .elv-financials-cell-addressFromTo-main__copy {
    padding-left: 4px;
    width: 12px;
    height: 12px;
  }
}
</style>
x1.00
