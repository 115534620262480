<template>
  <template v-if="isGroup">
    <GroupCell :params="props.params" />
  </template>

  <div v-else class="elv-table-entity-content">
    <!-- 分组下标 -->
    <GroupIndexCell :params="props.params" />
    <div class="elv-project-name-content">
      <img
        v-if="props.params.data?.currency?.logo"
        :style="{
          'border-radius': avatarShape() === 'circle' ? '50%' : '0'
        }"
        :onerror="useDefaultImage"
        :src="props.params.data?.currency?.logo"
        style="height: 20px; width: 20px"
      />
      <img
        v-if="!props.params.data?.currency?.logo && props.params.data?.currency?.name"
        :style="{
          'border-radius': avatarShape() === 'circle' ? '50%' : '0'
        }"
        src="@/assets/img/project/project-empty.png"
        style="height: 20px; width: 20px"
      />

      <div class="elv-project-name-content-right">
        <div v-show="props.params.data?.currency?.name" class="elv-project-name-content-name">
          {{ props.params.data?.currency?.name }}
        </div>
        <div v-if="props.params.data?.currency?.symbol" class="elv-project-name-content-symbol">
          {{ props.params.data?.currency?.symbol }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import GroupCell from './GroupCell.vue'
import GroupIndexCell from './GroupIndexCell.vue'
import projectDefaultImg from '@/assets/img/project/project-empty.png'

const props: any = defineProps<{
  params: any
}>()

const useDefaultImage = (event: any) => {
  event.target.src = projectDefaultImg
}

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const avatarShape = () => {
  if (props.params.column.colDef.maskType === 'SQUARE') {
    return 'square'
  }
  return 'circle'
}
</script>
<style lang="scss">
.elv-table-entity-content {
  display: flex;
  align-items: center;
  height: 42px;
}

.elv-project-name-content {
  display: flex;
  align-items: center;
  text-align: center;

  img {
    object-fit: contain;
    background: rgb(237, 240, 244);
  }

  .elv-project-name-index {
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: $elv-color-999BA0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 12px;
  }

  .elv-project-name-content-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    line-height: 16px;
    margin-top: 2px;

    .elv-project-name-content-name {
      position: relative;
      top: -2px;
      left: 1px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-transform: capitalize;
      color: $elv-color-0E1420;
      margin-left: 8px;
    }

    .elv-project-name-content-symbol {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 10px;
      transform: scale(0.91);
      line-height: 11px;
      color: #b3bfce;
      margin-left: 12px;
    }
  }
}

.elv-project-name-total-num {
  font-family: 'Barlow';
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: $elv-color-0E1420;
}

.elv-project-name-total-text-total {
  font-family: 'Plus Jakarta Sans';
  color: $elv-color-999BA0;
  font-weight: 400;
  margin-right: 8px;
}

.elv-project-name-total-text {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 12px;
  color: $elv-color-0E1420;
  margin-left: 4px;
}

.elv-project-avatar {
  background-color: transparent;
}
</style>
