<template>
  <div v-if="isShowIndex" class="elv-table-group-rows__index">
    {{ props.params.node?.rowIndex - props.params.node?.parent?.rowIndex }}
  </div>
</template>

<script setup lang="ts">
const props: any = defineProps<{
  params: any
}>()

const isShowIndex = computed(() => {
  return (props.params.node?.level === 2 || props.params.node?.level === 3) && props.params.colDef.columnIndex === 0
})
</script>

<style lang="scss">
.elv-table-group-rows__index {
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 13px;
  line-height: 42px;
  color: $elv-color-858B92;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 9px;
  padding-right: 9px;
  border-right: 1px solid #e4e7eb;
  height: 42px;
  width: 13px;
}
</style>
