<template>
  <div class="elv-table-number">
    <!-- 分组下标 -->
    <GroupIndexCell :params="props.params" />
    <span
      v-if="props.params.node?.rowPinned !== 'bottom'"
      :class="{ 'elv-table-number-group': props.params?.node?.group }"
    >
      <el-tooltip
        :show-arrow="false"
        effect="light"
        placement="top"
        popper-class="elv-table-tips"
        overlay-classname="elv-table-tips"
        :show-after="500"
        :disabled="!showCostBasisError"
      >
        <div>
          <img v-if="showCostBasisError" src="@/assets/img/reports/table-warning-filled.png" alt="error" />
          <p :title="`$${formatNumberValue.value}`">{{ formatNumberValue.formatValue }}</p>
        </div>

        <template #content>
          <div class="elv-table-cell-tips-content">
            {{ t('report.disposalExceedsBalanceInfo', { currency: props.params?.data?.currency }) }}
          </div>
        </template>
      </el-tooltip>
    </span>
    <div
      v-if="
        props.params.node?.rowPinned === 'bottom' &&
        (props.params.value?.cellName === 'Fair Value' || props.params.value?.cellName === 'Cost Basis')
      "
      class="elv-table-number-total"
    >
      <p :title="`$${formatNumberValue.value}`">{{ formatNumberValue.formatValue }}</p>
      <span>{{ t('common.sum') }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { findIndex } from 'lodash-es'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import GroupIndexCell from './GroupIndexCell.vue'
import { useReportStore } from '@/stores/modules/reports/index'

const props: any = defineProps<{ params: any }>()

const { t } = useI18n()
const reportStore = useReportStore()
const { issueList } = toRefs(reportStore)

const issueDataList = computed(() => {
  return issueList.value.filter((item) => item.type === 'INVENTORY_OVERSPEND')
})

const showCostBasisError = computed(() => {
  return (
    props.params?.node?.level === 0 &&
    props.params?.value?.cellName === 'Cost Basis' &&
    issueDataList.value.length &&
    findIndex(issueDataList.value, { currency: props.params?.data?.currency }) > -1
  )
})

const cellHeight = computed(() => {
  return props.params.node?.rowPinned === 'bottom' &&
    (props.params.value?.cellName === 'Fair Value' || props.params.value?.cellName === 'Cost Basis')
    ? '52px'
    : 'auto'
})

const cellMarginTop = computed(() => {
  return props.params.node?.rowPinned === 'bottom' &&
    (props.params.value?.cellName === 'Fair Value' || props.params.value?.cellName === 'Cost Basis')
    ? '6px'
    : '0px'
})

const cellJustifyContent = computed(() => {
  return props.params.value?.cellName === 'Transactions' ? 'flex-start' : 'flex-end'
})

const formatNumberValue = computed(() => {
  let formatValue = '-'
  let value = '-'
  switch (props.params.value?.cellName) {
    case 'Nominal Amount':
      value = props.params?.data?.amount
      if (props.params?.data?.amount === 0) {
        formatValue = props.params?.data?.amount
      } else {
        formatValue = props.params?.data?.amount ? utils.formatNumber(props.params?.data?.amount, 2) : '-'
      }
      break
    case 'Market Value':
      value = props.params?.data?.marketValueUsd
      if (props.params?.data?.marketValueUsd === 0) {
        formatValue = props.params?.data?.marketValueUsd
      } else {
        formatValue = props.params?.data?.marketValueUsd
          ? utils.fieldValueFormat(props.params?.data?.marketValueUsd, { dollar: true }, 'NUMBER')
          : '-'
      }
      break
    case '% of total net assets':
      value = props.params?.data?.shareOfNetAssets
      if (props.params?.data?.shareOfNetAssets === 0) {
        formatValue = props.params?.data?.shareOfNetAssets
      } else {
        formatValue = props.params?.data?.shareOfNetAssets
          ? utils.toPercent(props.params?.data?.shareOfNetAssets, 1)
          : '-'
      }
      break
    case 'Transactions':
      value = props.params?.data?.count
      formatValue = props.params?.data?.count ?? '-'
      break
    case 'IRR':
      value = props.params?.data?.irr
      if (props.params?.data?.irr === 0) {
        formatValue = props.params?.data?.irr
      } else {
        formatValue = props.params?.data?.irr ? utils.toPercent(props.params?.data?.irr, 1) : '-'
      }
      break
    case 'Multiples':
      value = props.params?.data?.multiples
      if (props.params?.data?.multiples === 0) {
        formatValue = props.params?.data?.multiples
      } else {
        formatValue = props.params?.data?.multiples ? `${utils.formatNumber(props.params?.data?.multiples, 2)}x` : '-'
      }
      break
    case 'Cost basis':
      value = props.params?.data?.costBasisUsd
      if (props.params?.data?.costBasisUsd === 0) {
        formatValue = props.params?.data?.costBasisUsd
      } else {
        formatValue = props.params?.data?.costBasisUsd
          ? utils.fieldValueFormat(props.params?.data?.costBasisUsd, { dollar: true }, 'NUMBER')
          : '-'
      }
      break
    case 'Realized Proceeds':
      value = props.params?.data?.realizedValueUsd
      if (props.params?.data?.realizedValueUsd === 0) {
        formatValue = props.params?.data?.realizedValueUsd
      } else {
        formatValue = props.params?.data?.realizedValueUsd
          ? utils.fieldValueFormat(props.params?.data?.realizedValueUsd, { dollar: true }, 'NUMBER')
          : '-'
      }
      break
    case 'Cost basis of Realized':
      value = props.params?.data?.realizedBasisUsd
      if (props.params?.data?.realizedBasisUsd === 0) {
        formatValue = props.params?.data?.realizedBasisUsd
      } else {
        formatValue = props.params?.data?.realizedBasisUsd
          ? utils.fieldValueFormat(props.params?.data?.realizedBasisUsd, { dollar: true }, 'NUMBER')
          : '-'
      }
      break
    case 'Quantity':
      value = props.params?.data?.quantity
      if (props.params?.data?.quantity === 0) {
        formatValue = props.params?.data?.quantity
      } else {
        formatValue = props.params?.data?.quantity ? utils.formatNumber(props.params?.data?.quantity, 2) : '-'
      }
      break
    case 'Fair Value':
      value = props.params?.data?.marketValue
      if (props.params?.data?.marketValue === 0) {
        formatValue = props.params?.data?.marketValue
      } else if (props.params.node?.rowPinned === 'bottom') {
        value = props.params?.data?.fieldValues?.marketValue
        formatValue = props.params?.data?.fieldValues?.marketValue
          ? utils.fieldValueFormat(props.params?.data?.fieldValues?.marketValue, { dollar: true }, 'NUMBER')
          : '-'
      } else {
        formatValue = props.params?.data?.marketValue
          ? utils.fieldValueFormat(props.params?.data?.marketValue, { dollar: true }, 'NUMBER')
          : '-'
      }
      break
    case 'Cost Basis':
      value = props.params?.data?.costBasis
      if (props.params?.data?.costBasis === 0) {
        formatValue = props.params?.data?.costBasis
      } else if (props.params.node?.rowPinned === 'bottom') {
        value = props.params?.data?.fieldValues?.costBasis
        formatValue = props.params?.data?.fieldValues?.costBasis
          ? utils.fieldValueFormat(props.params?.data?.fieldValues?.costBasis, { dollar: true }, 'NUMBER')
          : '-'
      } else {
        formatValue = props.params?.data?.costBasis
          ? utils.fieldValueFormat(props.params?.data?.costBasis, { dollar: true }, 'NUMBER')
          : '-'
      }
      break
    default:
      formatValue = '-'
      break
  }
  return { formatValue, value }
})
</script>
<style lang="scss">
.elv-table-cell-tips-content {
  padding: 10px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
  width: 220px;
}

.elv-table-number {
  height: v-bind('cellHeight');
  margin-top: v-bind('cellMarginTop');
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: v-bind('cellJustifyContent');
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 13px;
  color: $elv-color-0E1420;

  .elv-table-number-group {
    font-weight: 700;
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  .elv-table-number-total {
    display: flex;
    flex-direction: column;

    p {
      font-family: 'Barlow';
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #0e0f11;
      text-align: right;
    }

    span {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      zoom: 0.83;
      -moz-transform: scale(0.83);
      -moz-transform-origin: top left;
      color: #aaafb6;
      text-align: right;
    }
  }
}
</style>
