<template>
  <div class="elv-table-operating">
    <template v-if="props.params.node?.rowPinned !== 'bottom'">
      <div v-if="true" class="elv-table-operating-forReview">
        <el-tooltip
          :show-arrow="false"
          effect="light"
          placement="top"
          popper-class="elv-report-table-tips"
          overlay-classname="elv-report-table-tips"
          :show-after="500"
          :disabled="props.params?.data?.categoryNo === '00000'"
        >
          <div
            class="elv-table-operating-forReview-single"
            :class="{ 'is-disabled': props.params?.data?.categoryNo === '00000' }"
            @click="onReview"
          >
            <SvgIcon name="transactions-review" width="13.5" height="9.23" />
          </div>
          <template #content>
            <div class="elv-report-table-tips-content">{{ t('button.confirm') }}</div>
          </template>
        </el-tooltip>

        <el-tooltip
          :show-arrow="false"
          effect="light"
          placement="top"
          popper-class="elv-report-table-tips"
          overlay-classname="elv-report-table-tips"
          :show-after="500"
        >
          <SvgIcon
            class="elv-table-operating-forReview-rule"
            name="transactions-rule"
            width="18"
            height="18"
            @click="onBatchReview"
          />
          <template #content>
            <div class="elv-report-table-tips-content">
              {{
                props.params?.data?.categoryNo === '00000' ? t('report.batchSettingEvents') : t('report.batchConfirm')
              }}
            </div>
          </template>
        </el-tooltip>
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import { useReportStore } from '@/stores/modules/reports/index'
import ForReviewBatchReview from '@/pages/Financials/Project/Transactions/components/BatchReview.vue'

const props: any = defineProps<{ params: any }>()

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const onReview = async () => {
  try {
    if (props.params?.data?.categoryNo !== '00000') {
      reportStore.loading = true
      const params = {
        activityIds: [props.params?.data?.activityId]
      }
      await ReportsApi.quickReview(entityId.value, params)
      ElMessage.success(t('message.transactionCategorized'))
      reportStore.fetchIssueDataList(entityId.value)
      if (reportStore.entityDetail.guideStep < 2) {
        reportStore.fetchEntityDetail(entityId.value)
      }
      reportStore.batchEditSelected = []
      reportStore.checkTransactionsAll = false
      emitter.emit('onDeleteTableRow', [props.params?.node?.id])
      // emitter.emit('resetList')
      const { data } = await ReportsApi.getActivityEvent(entityId.value, { status: 'REVIEWED' })
      const transactionFilter = {
        ...reportStore.transactionFilter,
        categoryNos: data
      }
      reportStore.processTableListDataInit(entityId.value, 'reviewed', {
        limit: 10,
        page: 1,
        ...transactionFilter
      })
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    reportStore.loading = false
  }
}

const onBatchReview = () => {
  props.params?.api?.setSideBar({
    toolPanels: [
      {
        id: 'ForReviewBatchReview',
        labelDefault: 'ForReviewBatchReview',
        labelKey: 'ForReviewBatchReview',
        iconKey: 'columns',
        width: 258,
        toolPanel: ForReviewBatchReview,
        toolPanelParams: {
          data: props.params?.data
        }
      }
    ],
    defaultToolPanel: 'ForReviewBatchReview',
    hiddenByDefault: true
  })
  reportStore.unSelectedList = []
  reportStore.batchEditSelected = []
  const params = {
    fromAddress: props.params?.data?.fromAddress,
    toAddress: props.params?.data?.toAddress,
    currency: props.params?.data?.currency,
    categoryNo: String(props.params?.data?.categoryNo)
  }
  reportStore.batchReviewParams = params
  emitter.emit('resetList', true)
  setTimeout(() => {
    props.params?.columnApi?.setColumnsVisible(['transactions-event', 'Operating'], false)
    props.params?.api?.setSideBarVisible(true)
    props.params?.api?.openToolPanel('ForReviewBatchReview')
  }, 50)
}
</script>
<style lang="scss">
.elv-report-table-tips-content {
  padding: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
}

.elv-table-operating {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  .elv-table-operating-forReview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .elv-table-operating-forReview-single:not(.is-disabled) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      background: #f2f4ec;
      border-radius: 2px;
      cursor: pointer;

      svg {
        fill: #749a01;
      }

      &:hover {
        background: #f4fae2;

        svg {
          fill: #648600;
        }
      }
    }

    .elv-table-operating-forReview-single.is-disabled {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      cursor: not-allowed;

      svg {
        fill: #d0d4d9;
      }
    }

    .elv-table-operating-forReview-rule {
      margin-left: 10px;
      fill: #aaafb6;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        outline: none;
        fill: #000;
      }
    }
  }
}
</style>
