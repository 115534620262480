<template>
  <div
    v-if="!props.params.node?.group && props.params.node?.rowPinned !== 'bottom'"
    class="elv-financials-cell-addressFromTo-main"
  >
    <template v-if="props.params?.data?.from && props.params?.data?.to">
      <div>
        <AddressFromToItem :address="props.params?.data?.from" :is-show-logo="props.params?.data?.fromIsOfficial" />
      </div>
      <div class="elv-financials-cell-addressFromTo-middle">
        <img src="@/assets/img/arrow-right.png" alt="" style="width: 12px; height: 12px" />
      </div>
      <div>
        <AddressFromToItem :address="props.params?.data?.to" :is-show-logo="props.params?.data?.toIsOfficial" />
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import AddressFromToItem from './AddressFromToItem.vue'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
</script>
<style lang="scss">
.elv-financials-cell-addressFromTo-main {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  .elv-financials-cell-addressFromTo-middle {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
